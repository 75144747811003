.banner {
  position: relative;
  background-color: var(--cl-navy);
  padding-top: ac(108px, 64px);
  padding-bottom: ac(80px, 48px);
  overflow: hidden;
  &-decoration {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    &__custom {
      display: none;
    }
  }
  &-content {
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  &-subtitle {
    color: #80CAE0;
  }
  &-title {
    margin-bottom: ac(40px, 24px);
    color: var( --cl-navy-light);
    text-align: center;
  }
  &-descr {
    color: var(--cl-navy);
    text-align: center;
    margin-bottom: ac(40px, 22px);
  }
  &-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ac(24px, 16px);
    .btn-border {
      background-color: var(--cl-navy);
      text-transform: uppercase;
      border-color: var(--cl-blue);
    }
    @media (max-width: 560px) {
      flex-direction: column;
      width: 100%;
      .btn {
        width: 100%;
        max-width: 460px;
      }
    }
  }

  /* careers page */
  &-careers {
    background: var(--cl-blue-light-2);
    .banner {
      &-decoration {
        display: none;
        &__custom {
          display: block;
        }
      }
      &-subtitle {
        color: var(--cl-blue);
      }
      &-title {
        color: var(--cl-navy);
        margin-bottom: ac(24px, 18px);
      }
    }
  }
}