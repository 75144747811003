.testimonials {
  padding-top: ac(122px, 48px);
  padding-bottom: ac(64px, 32px);
  overflow: hidden;
  &-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: ac(48px, 28px);
  }
  &-name {
  }
  &-title {
    color: var(--cl-navy);
  }

  &-wrap {
    /* display: flex; */
    justify-content: center;
  }

  &-slider {
    max-width: 648px;
    overflow: visible;
    @media (max-width: 992px) {
      max-width: ac(648px, 327px, 375, 992);
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: ac(48px, 24px);
      width: 100%;
      gap: ac(32px, 24px);
      @media (max-width: 820px) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        gap: ac(24px, 16px, 375, 820);
      }
    }
  }
  .swiper-scrollbar {
    position: relative;
    width: 100%;
    inset: auto;
    background-color: #b9dfef;
    &-drag {
      background-color: var(--cl-blue);
    }
  }

  &-card {
    border: 1px solid rgba(43, 149, 184, 0.3);
    background-color: var(--cl-navy-light);
    padding-top: ac(24px, 16px);
    padding-right: ac(32px, 16px);
    padding-bottom: ac(26px, 24px);
    padding-left: ac(52px, 40px);
    max-width: 648px;
    position: relative;
    opacity: 0.5;
    transition: 0.3s all;
    @media (max-width: 992px) {
      max-width: ac(648px, 327px, 375, 992);
    }
    @media (max-width: 574px) {
      padding-left: ac(40px, 31px, 320, 575);
    }
    &__quote {
      position: absolute;
      top: ac(24px, 16px);
      left: ac(20px, 16px);
      max-width: ac(21px, 12px);
    }
    &__descr {
      position: relative;
      margin-bottom: ac(52px, 16px);
      color: var(--cl-dark-gray);
      font-size: ac(24px, 18px);
      line-height: 1.48;
      @media (max-width: 576px) {
        letter-spacing: 0.045px;
        line-height: 1.5;
      }
      --max-line-num: 6;
      @media (max-width: 992px) {
        --max-line-num: 9;
      }
      @media (max-width: 820px) {
        --max-line-num: 11;
      }
      p {
      }
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
    }
    &__company {
      max-width: ac(420px, 155px, 375);
      &-name {
        color: var(--cl-navy);
        margin-bottom: ac(12px, 8px);
      }
      &-descr {
        font-size: ac(20px, 15px);
        font-weight: 500;
        line-height: 1.4;
        font-family: var(--font-second);
        color: var(--cl-blue);
        @media (max-width: 574px) {
          max-width: ac(155px, 130px, 320, 375);
        }
      }
    }
    &__logo {
      width: ac(211px, 118px, 375);
      height: ac(118px, 66px, 375);
      background-color: #fff;
      border: 1px solid rgba(43, 149, 184, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ac(33px, 18px) ac(40px, 16px);
      position: absolute;
      bottom: -1px;
      right: -1px;
      /* transform: translate(33px, 27px); */
      /* 17, 39 */
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .swiper-slide-active {
    opacity: 1;
  }
}
