.pagination-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(44px, 40px);
  min-width: ac(44px, 40px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: none;
  i {
    font-size: 20px;
    color: var(--cl-blue);
    transition: color 0.3s ease;
  }

  &.pagination-btn-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.pagination-btn-disabled) {
      i {
        color: var(--cl-blue-900);
      }
    }
  }

  /*&.pagination-next {
    margin-left: ac(16px, 8px);
  }

  &.pagination-prev {
    margin-right: ac(16px, 8px);
  }*/
}

.pagination-num {
  text-align: center;
  font-size: ac(17px, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: all 0.3s ease;
  height: ac(44px, 40px);
  min-width: ac(44px, 40px);
  padding-left: 4px;
  padding-right: 4px;
  background: transparent;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s all;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    color: var(--cl-navy);
    transition: all 0.3s ease;
  }

  &.active {
    cursor: not-allowed;
    border: 1px solid var(--cl-blue-light);
    background: var(--cl-navy-light);
  }

  &.pointer-events-none {
    pointer-events: none;
    min-width: 28px;
    width: 28px;
    color: var(--cl-mid-gray);
  }

  &:hover {
    &:not(.active) {
      span {
        color: var(--cl-navy-500);
      }
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ac(8px, 4px);
}
