.first {
  position: relative;
  overflow: hidden;
  padding-top: calc(calc(var(--header-height) + var(--top-bar-height)) + ac(108px, 80px));
  padding-bottom: ac(108px, 25px, 375);
  &-bg-image {
    position: absolute;
    inset: 0;
    z-index: -1;
    top: ac(-500px, 0, 1440, 2560);
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &-bg-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.54) 49.93%, rgba(0, 0, 0, 0.72) 100%);
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    &--blue {
      background: rgba(5, 60, 128, 0.8);
    }
  }

  &-wrap {
    display: flex;
  }
  &-text {
    max-width: ac(600px, 460px, 920);
    @media (max-width: 920px) {
      max-width: none;
    }
  }
  &-page_name {
    color: var(--cl-navy-light);
    margin-bottom: ac(16px, 12px);
    font-size: ac(26px, 18px);
    line-height: 1.4;
    font-weight: 600;
  }
  &-title {
    color: var(--cl-navy-light);
    margin-bottom: 16px;
    @media (max-width: 768px) {
      br {
        /* display: none; */
      }
    }
  }
  &-descr {
    color: var(--cl-navy-light);
    margin-bottom: 40px;
    @media (max-width: 920px) {
      max-width: 640px;
    }
  }
  &-buttons {
    display: flex;
    gap: ac(32px, 12px);
    @media (max-width: 500px) {
      flex-direction: column;
      .btn {
        width: 100%;
      }
      .btn-link .btn__icon {
        width: 7px;
      }
    }
    .btn {

    }
  }
  &-image {
    img {
      width: 100%;
      height: 100%;
    }
  }

  /* index page */
  &-home {
    min-height: 630px;
    height: 100dvh;
    @media (max-width: 1020px) {
      height: auto;
      min-height: auto;
    }
    @media (max-width: 576px) {
      min-height: 520px;
      height: 100dvh;
    }
    .cont {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .first {
      &-descr {
        @media (max-width: 576px) {
          line-height: 1.4;
          font-size: 20px;
        }
      }
    }
  }

  /* about page */
  &-about {
    padding-top: ac(235px, 150px);
    padding-bottom: ac(75px, 48px);
    .first {
      &-wrap {
        align-items: flex-start;
        justify-content: space-between;
        gap: ac(32px, 32px);
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: ac(48px, 32px);
        }
      }
      &-text {
        max-width: ac(544px, 390px);
        margin-top: 45px;
        @media (max-width: 768px) {
          max-width: 520px;
        }
      }
      &-descr {
        margin-bottom: 0;
      }
      &-bg-gradient {
        background: rgba(5, 60, 128, 0.8);
      }
      &-image {
        border: 1px solid var(--cl-blue);
        max-width: ac(600px, 450px);
        height: auto;
        margin-right: ac(12px, 0px);
        @media (max-width: 768px) {
          max-width: 520px;
        }
      }
    }
    &-decoration {
      position: absolute;
      top: ac(158px, 120px);
      right: 0%;
      z-index: -1;
      max-width: ac(430px, 320px);
      height: 100%;
      @media (max-width: 768px) {
        top: auto;
        bottom: ac(0, -150px, 320, 768);
        height: auto;
        max-width: ac(320px, 300px, 320, 768);
      }
      img {

      }
    }
  }

  /* services page */
  &-services {
    padding-top: ac(300px, 180px);
    padding-bottom: ac(140px, 86px);
    .first {
      &-title {
        margin-bottom: ac(40px, 28px);
      }
      &-descr {
        font-size: ac(28px, 20px);
        font-weight: bold;
        line-height: 1.2;
      }
    }

    &-decoration {
      position: absolute;
      max-width: none;
      position: absolute;
      top: ac(58px, 5px, 920);
      right: 0;
      z-index: -1;
      height: 100%;
      @media (max-width: 920px) {
        top: ac(35px, 16px, 768, 920);
      }
      @media (max-width: 768px) {
        top: auto;
        bottom: 0;
      }
      img {
        width: 100%;
        height: 100%;
        @media (max-width: 768px) {
          object-fit: cover;
        }
      }
    }
  }

  /* internal service page */
  &-internal-service {
    padding-top: ac(230px, 150px);
    padding-bottom: ac(50px, 42px);
    .cont {
      padding-left: ac(33px, 20px);
    }
    .first {
      &-wrap {
        gap: ac(32px, 20px);
        justify-content: space-between;
        @media (max-width: 900px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
      &-bg-gradient {
        background: var(--cl-navy);
      }
      &-text {
        max-width: ac(505px, 430px);
        margin-top: ac(68px, 50px);
        @media (max-width: 900px) {
          max-width: 768px;
          margin-top: 0;
        }
      }
      &-descr {
        margin-bottom: 0;
      }
    }
    &-decoration {
      position: absolute;
      opacity: .2;
      max-width: none;
      position: absolute;
      top: ac(58px, 5px, 920);
      right: 0;
      z-index: -1;
      height: 100%;
      @media (max-width: 920px) {
        top: ac(35px, 16px, 768, 920);
      }
      @media (max-width: 768px) {
        top: auto;
        bottom: 0;
      }
      img {
        width: 100%;
        height: 100%;
        @media (max-width: 768px) {
          object-fit: cover;
        }
      }
    }

    &__slider {
      margin-right: ac(106px, 80px);
      .swiper {
        max-width: ac(495px, 345px, 900);
        overflow: visible;
        @media (max-width: 900px) {
          max-width: ac(576px, 324px, 375, 900);
        }
        @media (max-width: 900px) {
          max-width: ac(324px, 270px, 320, 375);
        }
      }
      &-item {
        max-width: ac(495px, 345px, 900);
        border: 1px solid var(--cl-blue);
        position: relative;
        transition: .3s all;
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          background-color: rgba(5, 60, 128, 0.0);
          position: absolute;
          inset: 0;
          transition: .3s;
        }

        &.swiper-slide-active {

        }
        &.swiper-slide-next {
          &::before {
            background-color: rgba(5, 60, 128, 0.5);
          }
        }
        &.swiper-slide-next + .swiper-slide {
          &::before {
            background-color: rgba(5, 60, 128, 0.8);
          }
        }
        img {
          width: 100%;
          object-fit: cover;
        }
        @media (max-width: 900px) {
          max-width: ac(576px, 324px, 375, 900);
        }
        @media (max-width: 900px) {
          max-width: ac(324px, 270px, 320, 375);
        }
      }
    }
  }

  /* health & safety page */
  &-health {
    padding-bottom: 0;
    padding-top: ac(247px, 164px);
    .cont {
      padding-left: ac(31px, 20px);
    }
    .first {
      &-title {
        font-size: ac(80px, 45px);
        @media (max-width: 576px) {
          br {
            display: none;
          }
        }
      }
      &-wrap {
        align-items: flex-start;
        justify-content: space-between;
        gap: ac(24px, 20px);
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: ac(48px, 32px);
        }
      }
      &-text {
        max-width: ac(544px, 390px);
        margin-top: 54px;
        @media (max-width: 768px) {
          margin-top: 0;
          max-width: 520px;
        }
      }
      &-descr {
        margin-bottom: 0;
      }
      &-image {
        max-width: ac(520px, 450px);
        height: auto;
        margin-right: ac(25px, 14px);
        @media (max-width: 768px) {
          max-width: 480px;
        }
      }
    }

    &-decoration {
      position: absolute;
      top: ac(147px, 125px);
      z-index: -1;
      max-width: ac(850px, 708px, 1440, 2560);
      right: ac(380px, -8px, 1440, 2560);
      height: 100%;
      @media (max-width: 1440px) {
        max-width: ac(708px, 390px);
        right: ac(-8px, -50px);
      } 
      /* @media (max-width: 1200px) {
        max-width: ac(708px, 520px);
        right: ac(-8px, -1px);
      } */
      @media (max-width: 768px) {
        top: auto;
        bottom: ac(0, -150px, 320, 768);
        right: -116px;
        height: auto;
        max-width: ac(520px, 400px, 320, 768);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  /* careers page */
  &-careers {
    padding-top: ac(154px, 128px, 940);
    padding-bottom: 0;
    @media (max-width: 940px) {
      padding-bottom: ac(80px, 32px, 320, 940);
    }
    .first {
      &-title {
        color: #FFF;
      }
      &-page_name {
        font-weight: bold;
      }
      &-wrap {
        align-items: center;
        @media (max-width: 940px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &-text {
        min-width: ac(485px, 340px, 940);
        max-width: none;
        margin-top: 40px;
        @media (max-width: 940px) {
          min-width: auto;
          max-width: 668px;
        }
      }
      &-bg-image {
        z-index: 1;
        @media (max-width: 940px) {
          min-width: 1560px;
          opacity: 0.9;
        }
      }
      &-bg-gradient {
        background: var(--cl-navy);
      }
      &-page_name {
        color: #80CAE0;
        font-size: ac(30px, 24px);
      }
      &-title {
        margin-bottom: ac(32px, 20px);
        @media (max-width: 940px) {
          br {
            display: none;
          }
        }
      }
      &-descr {
        color: #B9DFEF;
        font-size: ac(20px, 18px);
      }
      &-image {
        transform: translateX(ac(73px, 17px, 940));
        margin-top: 1px;
        min-width: ac(772px, 582px, 940);
        border: 1px solid var(--cl-blue);
        @media (max-width: 940px) {
          transform: translateX(0);
          min-width: auto;
        }
      }
    }
    .cont {
      position: relative;
      z-index: 2;
      padding-left: ac(34px, 20px);
    }
  }
}