h1,
h2,
h3,
h4 {
  font-weight: 500;
  line-height: 130%;
  color: var(--cl-grey);
}

h1 {
  font-size: ac(70px, 37px);
  line-height: normal;

  @mixin max-sm {
    line-height: 129%;
  }
}

h2 {
  font-size: ac(50px, 27px);
  line-height: normal;

  @mixin max-sm {
    line-height: 140%;
  }

  &.subtitle {
    font-size: ac(35px, 22px);
    line-height: 140%;
  }
}

h3 {
  font-size: ac(25px, 22px);
  line-height: normal;
  font-weight: 600;
}

h4 {
  font-size: ac(20px, 18px);
  line-height: 140%;
  font-weight: 400;

  @mixin max-sm {
    line-height: 155%;
  }
}

h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;

  line-height: 2;
}

p {
  /* font-size: ac(18px, 16px);
  font-weight: 400;
  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  } */
}

li {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }
}

a {
  font-size: ac(20px, 16px);
  font-weight: 500;
  display: inline-block;
  line-height: 100%;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  h4 {
    color: var(--cl-medium-grey);
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  li,
  p {
    /*Not Design*/
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-white);
  }
}

.heading-1 {
  font-size: ac(80px, 52px);
  font-weight: 700;
  line-height: 1.08;
}

.heading-2 {
  font-size: ac(68px, 42px);
  font-weight: 700;
  line-height: 1.2;
}

.heading-3 {
  font-size: ac(56px, 44px);
  font-weight: 700;
  line-height: 1.25;
  @media (max-width: 374px) {
    font-size: ac(44px, 38px, 320, 374);
  }
}

.heading-4 {
  font-size: ac(44px, 32px);
  font-weight: 700;
  line-height: 1.3;
  @media (max-width: 374px) {
    font-size: ac(44px, 28px, 320, 374);
  }
}

.heading-5 {
  font-size: ac(30px, 24px);
  font-weight: 700;
  line-height: 1.2;
}

.heading-6 {
  font-size: ac(28px, 22px);
  font-weight: 700;
  line-height: 1.17;
}

.subtitle-1 {
  font-size: ac(26px, 24px);
  font-weight: 600;
  line-height: 1.35;
}

.subtitle-2 {
  font-size: ac(24px, 18px);
  font-weight: 400;
  line-height: 1.48;
}

.subtitle-3 {
  font-size: ac(20px, 18px);
  font-weight: 500;
  line-height: 1.4;
}

.subtitle-4 {
  font-size: ac(18px, 16px);
  font-weight: 500;
  line-height: 1.38;
}

.button-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: .34px;
}

.body-text-1 {
  font-family: var(--font-second);
  font-size: ac(20px, 16px);
  font-weight: 400;
  line-height: 1.55;
  letter-spacing: .05px;
}

.body-text-2 {
  font-family: var(--font-second);
  font-size: ac(18px, 16px);
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: .04px;
}

.body-text-3 {
  font-family: var(--font-second);
  font-size: ac(16px, 16px);
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: .04px;
}

.text-link {
  font-size: ac(17px, 17px);
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: .02px;
}