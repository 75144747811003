.about-health {
  overflow: hidden;
  .cont {
    padding-left: ac(30px, 20px);
    padding-right: ac(22px, 16px);
  }
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ac(108px, 64px);
    padding-bottom: ac(108px, 64px);
    gap: ac(32px, 20px);
    @media (max-width: 880px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &-image {
    /* max-width: ac(600px, 400px); */
    width: 47%;
    position: relative;
    border: 1px solid #80CAE0;
    @media (max-width: 880px) {
      max-width: 600px;
      width: 100%;
    }
    img {

    }
    &__decoration {
      position: absolute;
      left: -48px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      max-width: ac(336px, 190px);
      animation: redraw 0.5s;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 880px) {
        max-width: ac(320px, 240px, 320, 880);
        top: ac(-120px, -165px, 320, 880);
        transform: translateY(0);
      }
    }
  }

  &-text {
    /* max-width: ac(580px, 390px); */
    width: 49%;
    @media (max-width: 880px) {
      max-width: 670px;
      width: 100%;
    }
  }
  &-title {
    color: var(--cl-navy);
    margin-bottom: ac(24px, 20px);
  }
  &-subtitle {
    color: var(--cl-navy);
    margin-bottom: 16px;
  }
  &-descr {
    color: var(--cl-dark-gray);
    p {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

@keyframes redraw {
  from { left: 0; }
  to { left: -48px; }
}