.health {
  padding: ac(107px, 24px) 0;
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ac(24px, 22px, 992);
    @media (max-width: 992px) {
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 40px;
    }
  }

  &-image {
    max-width: ac(520px, 360px, 992);
    max-height: ac(520px, 360px, 992);
    height: 100%;
    position: relative;
    z-index: 2;
    border: 1px solid #D0D1D3;
    @media (max-width: 992px) {
      max-width: ac(520px, 303px, 620, 992);
      max-height: ac(520px, 303px, 620, 992);
    }
    @media (max-width: 374px) {
      width: ac(303px, 260px, 320, 374);
      height: ac(303px, 260px, 320, 374);
    }
    &__wrap { 
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: ac(577px, 390px, 992);
      height: ac(577px, 390px, 992);
      position: relative;
      @media (max-width: 992px) {
        width: ac(565px, 327px, 620, 992);
        height: ac(565px, 327px, 620, 992);
      }
      @media (max-width: 374px) {
        width: ac(327px, 280px, 320, 374);
        height: ac(327px, 280px, 320, 374);
      }
    }
    img { 
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &__decoration {
      position: absolute;
      top: 0;
      left: 0;
      max-width: ac(520px, 303px);
      height: auto;
      z-index: 0;
      @media (max-width: 992px) {
        max-width: ac(520px, 303px, 620, 992);
      }
      @media (max-width: 374px) {
        width: ac(303px, 260px, 320, 374);
        height: ac(303px, 260px, 320, 374);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-text {
    max-width: ac(576px, 512px, 992);
    @media (max-width: 1230px) {
      max-width: 56%;
    }
    @media (max-width: 992px) {
      max-width: none;
    }
  }
  &-name { 

  }
  &-title {
    margin-bottom: 24px;
    color: var(--cl-navy);
  }
  &-subtitle {
    margin-bottom: 16px;
    color: var(--cl-navy);
    font-weight: 500;
    font-size: ac(20px, 18px);
    line-height: 1.4;
    font-family: var(--font-second);
  }

  &-logos {
    display: flex;
    flex-wrap: wrap;
    gap: ac(16px, 8px);
    @media (max-width: 460px) {
      justify-content: center;
    }
  }
  &-logo {
    width: 160px;
    height: 108px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: ac(160px, 108px, 320, 768);
      height: 73.6px;
    }
    img {
      /* width: 100%; */
      aspect-ratio: 1.5;
    }
  }

  &-buttons {
    display: flex;
    margin-top: ac(40px, 32px);
    @media (max-width: 620px) {
      flex-direction: column;
      .btn {
        width: 100%;
      }
    }
  }
}