.case-studies {
  padding-top: ac(228px, 120px);
  padding-bottom: ac(80px, 62px);
  position: relative;
  overflow: hidden;
  .cont {
    padding-left: ac(33px, 20px);
  }
  &__decoration {
    position: absolute;
    width: 100%;
    right: 0;
    top: ac(120px, 90px);
    z-index: -1;
  }
  &-first {
    margin-bottom: ac(64px, 32px);
  }
  &-title {
    color: var(--cl-navy);
    margin-bottom: ac(48px, 32px);
    @media (max-width: 374px) {
      font-size: ac(52px, 48px, 320, 574);
    }
  }
  /* search, filters */
  &-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;
    }
  }
  &-search {
    &__wrap {
      display: flex;
      align-items: center;
      width: 100%;
      @media (max-width: 374px) {
        flex-direction: column;
      }
      .meta-input {
        max-width: ac(354px, 280px);
        width: 100%;
        @media (max-width: 800px) {
          max-width: 380px;
        }
        input {
          color: var(--cl-navy);
          &::placeholder {
            color: var(--cl-navy)!important;
          }
        }
      }
    }

    &__submit {
      background-color: var(--cl-blue);
      color: #FFF;
      font-size: 16px;
      line-height: 1.32;
      letter-spacing: 1.2px;
      height: ac(48px, 42px);
      padding: 0 28px 0 22px;
      font-weight: bold;
      letter-spacing: 1.28px;
      text-transform: uppercase;
      transition: .3s;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 374px) {
        width: 100%;
        margin-top: 12px;
      }
      i {
        margin-right: ac(8px, 6px);
        font-size: ac(18px, 14px);
      }
      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  &-filter {
    &__wrap {
      gap: ac(24px, 12px);
      display: flex;
      align-items: center;
      .meta-select {
        width: ac(184px, 150px);
        @media (max-width: 576px) {
          width: ac(150px, 134px, 320, 576);
        }
        .choices {
          &__inner {
            border-color: var(--cl-blue-light);
          }
          /* default item? */
          &__placeholder {
            color: var(--cl-navy);
            opacity: 1;
          }

          /* body */
          &__list {
            padding: 0;
            background-color: #FFF;

            .choices__item {
              color: var(--cl-navy);
              &.is-selected {
                color: var(--cl-black);
                }
              }

              /* wrapper __list */
              &--dropdown {
                .choices__item {
                  color: var(--cl-mid-gray);
                }
              }
          }
        }
      }
    }
  }

  &-wrap {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(24px, 16px);
    grid-row-gap: ac(32px, 18px);
    margin-bottom: ac(64px, 32px);
    @media (max-width: 900px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: 576px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
  &__card {
    max-width: 392px;
    width: 100%;
    max-height: 555px;
    height: 100%;
    @media (max-width: 900px) {
      max-width: 422px;
    }
    @media (max-width: 576px) {
      max-width: 460px;
    }
    &-image {
      max-width: 464px;
      width: 100%;
      margin-bottom: ac(28px, 16px);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1.5;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 265px;
      @media (max-width: 680px) {
        height: ac(250px, 240px, 375, 680);
      }
    }
    &-block {
      height: 100%;
    }
    &-tags {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: ac(20px, 16px);
      gap: ac(8px, 6px);
    }
    &-tag {
      font-size: 14px;
      line-height: 1.32;
      letter-spacing: 2.24px;
      padding: ac(7px, 6px) ac(12px, 8px);
      border: 1px solid var(--cl-light-gray);
      font-weight: 700;
      color: var(--cl-blue);
      transition: .24s all;
      text-transform: uppercase;
    }
    &-title {
      color: var(--cl-navy);
      margin-bottom: ac(14px, 12px);
    }
    &-descr {
      --max-line-num: 3;
      @media (max-width: 720px) {
        /* --max-line-num: 4; */
      }
      @media (max-width: 576px) {
        /* --max-line-num: 3; */
        min-height: auto;
      }

    }
    &-link {
      color: var(--cl-navy);
      .btn__icon {
        i {
          color: var(--cl-blue);
        }
      }
      &:hover {
        color: var(--cl-blue);
      }
    }
  }

  &-pagination {

  }
}