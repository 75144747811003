.qualities {
  position: relative;
  padding-top: ac(102px, 58px);
  padding-bottom: ac(80px, 52px);
  overflow: hidden;
  .cont {
    padding-left: ac(33px, 24px);
    padding-right: ac(23px, 20px);
  }
  &-decoration {
    position: absolute;
    /* bottom: ac(-415px, -135px, 1440, 2560); */
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    min-width: 2424px;
    width: 100%;
    height: 220px;
    @media (max-width: 640px) {
      min-width: 1920px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    /* @media (max-width: 1440px) {
      bottom: ac(-135px, -30px, 320, 1440);
      min-width: 820px;
    } */
  }
  &-wrap {
    display: flex;
    justify-content: space-between;
    gap: ac(24px, 18px);
    @media (max-width: 820px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 32px;
    }
  }

  &-text {
    max-width: ac(552px, 380px);
    @media (max-width: 820px) {
      max-width: 768px;
    }
  }
  &-title {
    color: var(--cl-navy);
    margin-bottom: ac(30px, 24px);
  }
  &-subtitle {
    color: var(--cl-navy);
    font-weight: 500;
    font-family: var(--font-second);
    margin-bottom: 16px;
  }
  &-descr {
    color: var(--cl-dark-gray);
    margin-bottom: ac(40px, 28px);
    font-family: var(--font-second);
    p {
      &:not(:last-child) {
        margin-bottom: ac(14px, 10px);
      }
    }
  }

  &-cards {

  }
  &-card {
    padding: ac(32px, 20px);
    padding-bottom: ac(36px, 24px);
    background-color: #FFF;
    border: 1px solid var(--cl-blue-light);
    max-width: ac(600px, 520px);
    &:not(:last-child) {
      border-bottom: none;
    }
    @media (max-width: 820px) {
      max-width: 768px;
    }
    &__nav {
      margin-bottom: ac(24px, 16px);
      display: flex;
      align-items: center;
      gap: ac(24px, 20px);
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
    }
    &__icon {
      padding: ac(12px, 10px);
      border: 1px solid var(--cl-light-gray);
      background-color: var(--cl-blue);
      img {
        width: ac(56px, 48px);
        height: ac(56px, 48px);
      }
    }
    &__title {
      font-size: ac(28px, 20px);
      color: var(--cl-navy);
      line-height: 1.2;
      font-weight: bold;
    }

    &__descr {
      color: var(--cl-dark-gray);
      p {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}