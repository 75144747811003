.values {
  position: relative;
  overflow: hidden;
  background-color: var(--cl-navy);
  padding-top: ac(130px, 82px);
  padding-bottom: ac(80px, 64px);
  &-bg-decoration {
    position: absolute;
    inset: 0;
    z-index: 0;
    left: -50%;
    transform: translateX(50%);
    width: 100%;
    @media (max-width: 640px) {
      opacity: 0.6;
      img {
        object-fit: cover;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .cont {
    position: relative;
    z-index: 2;
  }
  &-nav {
    margin-bottom: ac(64px, 48px);
  }
  &-title {
    color: var(--cl-navy-light);
  }
  &-name {

  }

  &-wrap {

  }
  .swiper {
    overflow: visible;
    @media (max-width: 992px) {
      max-width: 480px;
    }
    @media (max-width: 576px) {
      max-width: ac(480px, 310px, 320, 576);
    }
    &-wrapper {
      @media (min-width: 993px) {
        grid-column-gap: ac(24px, 18px);
        grid-row-gap: ac(24px, 20px);
        grid-template-columns: repeat(3, minmax(0, 1fr));
        display: grid;
      }
    }
  }
  .swiper-buttons {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      margin: ac(32px, 28px, 375, 992) auto 0;
    }
  }
  &-card {
    position: relative;
    padding-top: ac(56px, 38px);
    padding-right: ac(32px, 18px);
    padding-bottom: ac(45px, 20px);
    padding-left: ac(32px, 18px);
    background-color: var(--cl-navy);
    border: 1px solid var(--cl-blue);
    width: 100%;
    height: auto;
    @media (max-width: 992px) {
      max-width: 480px;
    }
    @media (max-width: 576px) {
      max-width: ac(480px, 310px, 320, 576);
      height: auto;
    }
    &__icon {
      width: ac(80px, 72px);
      height: ac(80px, 72px);
      background-color: var(--cl-navy);
      border: 1px solid var(--cl-blue);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: ac(-40px, -36px);
      left: ac(32px, 24px);
      img {
        width: ac(56px, 48px);
        height: ac(56px, 48px);
      }
    }
    &__title {
      color: #FFF;
      margin-bottom: ac(16px, 14px);
    }
    &__descr {
      /* --max-line-num: 8; */
      color: var(--cl-navy-light);
      max-height: 230px;
      overflow: auto;
      scrollbar-width: thin;
    }
  }
}