.our-services {
  padding-top: ac(108px, 48px);
  padding-bottom: ac(64px, 32px);
  background-color: var(--cl-blue-light-2);
  overflow: hidden;
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: ac(56px, 36px);
    padding-right: ac(11px, 0);
    @media (max-width: 820px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 28px;
    }
    &-text {
    }
  }
  &-name {

  }
  &-title {
    color: var(--cl-navy);
    @media (max-width: 480px) {
      max-width: 320px;
    }
  }
  &-btn {
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  &__wrap {
    
  }
  &__slider {
    overflow: visible;
    max-width: 704px;
    margin-left: 0;
    @media (max-width: 900px) {
      max-width: ac(704px, 327px, 375, 900);
    }
    @media (max-width: 680px) {
      max-width: ac(470px, 327px, 375, 680);
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: ac(48px, 24px);
      width: 100%;
      gap: ac(32px, 24px);
      @media (max-width: 820px) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        gap: ac(24px, 16px, 375, 820);
      }
    }
  }
  .swiper-scrollbar {
    position: relative;
    width: 100%;
    inset: auto;
    background-color: #B9DFEF;
    &-drag {
      background-color: var(--cl-blue);
    }
  }
  &__card {
    max-width: 704px;
    width: 100%;
    display: flex;
    &:hover {
      .our-services__card-image img {
        scale: 1.03;
      }
      .our-services__card-link {
        color: var(--cl-blue);
        .btn__icon {
          i {
            color: var(--cl-blue);
          }
          transform: translateX(4px);
        }
      }
    }
    @media (max-width: 900px) {
      max-width: ac(704px, 327px, 375, 900);
    }
    @media (max-width: 680px) {
      flex-direction: column;
      max-width: ac(470px, 327px, 375, 680);
    }
    &-image {
      min-width: 289px;
      /* height: ac(339px, 337px); */
      border: 1px solid var(--cl-blue);
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 0.85 / 1;
        transition: .3s all;
      }
      @media (max-width: 900px) {
        min-width: 240px;
      }
      @media (max-width: 680px) {
        min-width: auto;
        width: 100%;
        max-width: 480px;
        img {
          aspect-ratio: 3 / 2;
        }
      }
    }
    &-text {
      border: 1px solid rgba(43, 149, 184, 0.5);
      border-left: 0;
      background-color: var(--cl-white);
      padding: ac(32px, 20px);
      padding-bottom: ac(24px, 8px);
      @media (max-width: 680px) {
        border: none;
      }
    }
    &-nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
    }
    &-icon {
      max-width: 120px;
      padding: ac(12px, 8px) ac(32px, 22px);
      border: 2px solid var(--cl-blue);
      border-radius: 100px;
      margin-right: ac(20px, 16px);
      img {
        max-width: ac(56px, 40px);
        min-width: 100%;
        height: auto;
      }
    }
    &-title {

    }
    &-descr {
      margin-bottom: ac(16px, 4px, 375);
      /* --max-line-num: 5; */
      min-height: 120px;
      max-height: 124px;
      padding-right: 4px;
      overflow: auto;
      scrollbar-width: thin;
      @media (max-width: 1300px) {
        max-height: ac(147px, 126px, 840, 1300);
      }
      @media (max-width: 840px) {
        max-height: 124px;
      }
      @media (max-width: 576px) {
        padding-right: 0;
      }
    }
    &-bottom {
      display: flex;
      justify-content: flex-end;
    }
    &-link {
      color: var(--cl-navy);
      .btn__icon {
        i {
          color: var(--cl-blue);
        }
      }
      &:hover {
        color: var(--cl-blue);
      }
    }
  }


}