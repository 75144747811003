@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;0,900;1,400;1,600;1,700&display=swap');

:root {
  --cl-dark: #000000;
  --cl-dark-rgb: 0 0 0;

  --cl-navy: #053D80;
  --cl-navy-500: #6A84CE;
  --cl-navy-light: #F1F3FE;

  --cl-blue-900: #00536F;
  --cl-blue: #2B94B8;
  --cl-blue-light: #80CAE0;
  --cl-blue-light-2: #ECF5FA;
  --cl-blue-light-80: rgba(128, 201, 224, 0.8);

  --cl-black: #333333;
  --cl-dark-gray: #4C4E50;
  --cl-mid-gray: #75767A;
  --cl-light-gray: #D0D1D3;

  --cl-white: #ffffff;
  --cl-white-80: rgba(255, 255, 255, 0.8);
  --cl-white-rgb: 255 255 255;

  --font-main: "Titillium Web", sans-serif;
  --font-second: "Roboto", sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;
  --header-height: ac(120px, 88px);
  --top-bar-height: ac(40px, 36px);
  --top-height: ac(160px, 124px);

  --vh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
