.work-type {
  padding: ac(80px, 64px) 0;
  padding-top: ac(74px, 64px);
  .cont {
    padding-left: ac(33px, 24px);
    padding-right: ac(23px, 20px);
  }
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ac(46px, 18px);
    @media (max-width: 860px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &-left {
    /* max-width: ac(658px, 380px); */
    width: 53.5%;
    min-width: 386px;
    overflow: hidden;
    background-color: var(--cl-blue-light-2);
    border: 1px solid var(--cl-blue-light);
    transition: .6s max-height ease-in-out;

    padding: ac(44px, 22px);
    padding-right: ac(38px, 22px);
    padding-top: ac(51px, 26px);
    @media (max-width: 860px) {
      display: none;
    }
  }
  /* descktop left content */
  &-text {
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    transition: .3s all;
    &__title {
      color: var(--cl-navy);
      margin-bottom: ac(24px, 18px);
    }
    &__descr {
      color: var(--cl-dark-gray);
      max-height: 420px;
      overflow: auto;
      p {
        &:not(:last-child) {
          margin-bottom: ac(20px, 10px);
        }
      }
    }
    &__btn {
      margin-top: ac(32px, 20px);
    }
    &.active {
      opacity: 1;
      /* max-height: 800px; */
      visibility: visible;
    }
  }

  &-right {
    max-width: ac(516px, 460px);
    width: 100%;
    @media (max-width: 860px) {
      max-width: none;
    }
  }
  &-title {
    color: var(--cl-navy);
    margin-bottom: ac(28px, 20px);
    @media (max-width: 374px) {
      font-size: ac(28px, 26px, 320, 375);
    }
  }

  &-tabs {
    gap: 8px;
    display: flex;
    flex-direction: column;
  }
  &-tab {
    height: auto;
    max-height: 64px;
    width: 100%;
    border: 1px solid var(--cl-blue-light);
    overflow: hidden;
    transition: .6s all;
    /* descktop tab toggle + mobile tab toggle */
    &__nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      cursor: pointer;
      padding: 0 12px 0 ac(20px, 18px);
      gap: 8px;
      span {
        color: var(--cl-navy);
        font-size: ac(20px, 18px);
        line-height: 1.4;
        font-weight: 500;
        font-family: var(--font-second);
        transition: .3s all;
      }
      &-icon {
        background-color: var(--cl-blue);
        --size: ac(40px, 32px);
        min-height: var(--size);
        min-width: var(--size);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s all;
        i {
          font-size: ac(16px, 14px);
          color: #FFF;
        }
      }
      &:hover {
        span {
          color: var(--cl-navy-500);
        }
        .work-type-tab__nav-icon {
          background-color: var(--cl-blue-light);
        }
      }
    }
    /* mobile content */
    &__body {
      display: none;
      padding: 14px 16px 14px 16px;
      transition: .2s all;
      opacity: 0;
      color: var(--cl-dark-gray);
      p {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      &.active {
        @media (max-width: 860px) {
          display: block;
          background-color: #FFF;
          opacity: 1;
        }
      }
    }

    /* tab active */
    &.active {
      border: 1px solid var(--cl-blue);
      .work-type-tab__nav {
        background-color: var(--cl-blue);
      }
      span {
        color: #FFF;
      }
      .work-type-tab__nav-icon {
        opacity: 0;
      }
      @media (max-width: 860px) {

      }
    }
  }
  /* internal studies page */
  &.work-type-int-studies {
    .work-type-text {
      background-color: var(--cl-navy-light);
    }
    .work-type-right {
      margin-top: ac(-16px, 0);
    }
  }
}