.latest-projects {
  padding-top: ac(108px, 48px);
  padding-bottom: ac(64px, 32px);
  overflow: hidden;
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: ac(56px, 36px);
    @media (max-width: 820px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 28px;
    }
    &-text {
    }
  }
  &-name {
  }
  &-title {
    color: var(--cl-navy);
  }
  &-btn {
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  &__wrap {
  }
  &__slider {
    overflow: visible;
    max-width: 464px;
    margin-left: 0;
    @media (max-width: 680px) {
      max-width: ac(464px, 244px, 375, 680);
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: ac(48px, 24px);
      width: 100%;
      gap: ac(32px, 24px);
      @media (max-width: 820px) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        gap: ac(24px, 16px, 375, 820);
      }
    }
  }
  .swiper-scrollbar {
    position: relative;
    width: 100%;
    inset: auto;
    background-color: var(--cl-navy-light);
    &-drag {
      background-color: var(--cl-blue);
    }
  }

  &__card {
    max-width: 464px;
    width: 100%;
    &:hover {
      .latest-projects__card-image img {
        transform: scale(1.05);
      }
      .latest-projects__card-link {
        color: var(--cl-blue);
        .btn__icon {
          transform: translateX(4px);
        }
      }
    }
    @media (max-width: 680px) {
      max-width: ac(464px, 244px, 375, 680);
    }
    &-image {
      max-width: 464px;
      width: 100%;
      margin-bottom: ac(28px, 16px);
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1.5;
        transition: .3s all;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 680px) {
        height: ac(250px, 240px, 375, 680);
      }
    }
    &-block {
      height: 100%;
    }
    &-tags {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: ac(20px, 16px);
      gap: ac(8px, 6px);
    }
    &-tag {
      font-size: 14px;
      line-height: 1.32;
      letter-spacing: 2.24px;
      padding: ac(7px, 6px) ac(12px, 8px);
      border: 1px solid var(--cl-light-gray);
      font-weight: 700;
      color: var(--cl-blue);
      transition: 0.24s all;
      text-transform: uppercase;
      /* &:hover {
        border: 1px solid var(--cl-mid-gray);
        color: var(--cl-blue-900);
      } */
    }
    &-title {
      color: var(--cl-navy);
      margin-bottom: ac(14px, 12px);
    }
    &-descr {
      min-height: 114px;
      /* margin-bottom: ac(24px, 8px, 375); */
      /* margin-bottom: auto; */
      --max-line-num: 4;
      @media (max-width: 720px) {
        --max-line-num: 5;
      }
      @media (max-width: 576px) {
        --max-line-num: 3;
        min-height: auto;
      }
    }
    &-link {
      color: var(--cl-navy);
      transition: 0.3s all;
      .btn__icon {
        i {
          color: var(--cl-blue);
        }
      }
      &:hover {
        color: var(--cl-blue);
      }
    }
  }
}
