.about {
  padding: ac(80px, 24px) 0;
  padding-top: ac(100px, 32px);
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ac(24px, 22px, 992);
    @media (max-width: 992px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 40px;
    }
  }

  &-image { 
    max-width: ac(520px, 360px, 992);
    max-height: ac(520px, 360px, 992);
    height: 100%;
    position: relative;
    z-index: 2;
    border: 1px solid #D0D1D3;
    @media (max-width: 992px) {
      max-width: ac(520px, 303px, 620, 992);
      max-height: ac(520px, 303px, 620, 992);
    }
    @media (max-width: 374px) {
      width: ac(303px, 260px, 320, 374);
      height: ac(303px, 260px, 320, 374);
    }
    &__wrap { 
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: ac(577px, 390px, 992);
      height: ac(577px, 390px, 992);
      position: relative;
      @media (max-width: 992px) {
        width: ac(565px, 327px, 620, 992);
        height: ac(565px, 327px, 620, 992);
      }
      @media (max-width: 374px) {
        width: ac(327px, 280px, 320, 374);
        height: ac(327px, 280px, 320, 374);
      }
    }
    img { 
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &__decoration { 
      position: absolute;
      top: 0;
      left: 0;
      max-width: ac(520px, 303px);
      height: auto;
      z-index: 0;
      @media (max-width: 992px) {
        max-width: ac(520px, 303px, 620, 992);
      }
      @media (max-width: 374px) {
        width: ac(303px, 260px, 320, 374);
        height: ac(303px, 260px, 320, 374);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-text {
    max-width: ac(588px, 680px, 992);
    margin-top: ac(19px, 10px);
    @media (max-width: 1230px) {
      max-width: 56%;
    }
    @media (max-width: 992px) {
      max-width: none;
      margin-top: 0;
    }
  }
  &-name { 

  }
  &-title {
    margin-bottom: 24px;
    color: var(--cl-navy);
  }
  &-subtitle { 
    margin-bottom: ac(16px, 20px);
    color: var(--cl-navy);
    line-height: 1.48;
    font-size: ac(24px, 20px);
    @media (max-width: 576px) {
      line-height: 1.4;
    }
  }
  &-descr {
    line-height: 1.56;
    font-size: ac(20px, 18px);
    color: var(--cl-dark-gray);
  }

  &-buttons {
    display: flex;
    /* gap: 16px; */
    margin-top: ac(40px, 32px);
    @media (max-width: 620px) {
      flex-direction: column;
      .btn {
        width: 100%;
      }
      .btn-link .btn__icon {
        width: 7px;
      }
    }
    .btn {
      
    }
    .btn-base {
      margin-right: ac(32px, 10px, 1200);
      @media (max-width: 620px) {
        margin-bottom: 16px;
      }
    }
    .btn-link {
      color: var(--cl-navy);
      margin-bottom: ac(16px, 8px);
      &:not(:last-child) {
        margin-right: ac(16px, 10px, 1280);
      }
      @media (max-width: 576px) {
        height: 40px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .btn__icon {
        i {
          color: var(--cl-blue);
        }
      }
    }
  }

  /* home page */
  &.about-home {
    .about {
      padding: ac(80px, 24px) 0;
    }
  }

  /* about page section */
  &-page {
    .about {
      &-buttons {
        .btn-link {
          .btn__icon {
            margin-left: ac(9px, 4px);
            i {
              font-size: ac(19px, 14px);
            }
          }
        }
      }
    }
  }
  /* health-safety page */
  &.health-safety {
    padding-top: ac(76px, 32px);
    .cont {
      /* padding-left: ac(33px, 20px); */
    }
    .about {
      &-wrap {
        @media (max-width: 992px) {
          flex-direction: column-reverse;
        }
      }
      &-text {
        margin-top: 0;
        padding-left: ac(24px, 0);
      }
      &-image {
        &__wrap {
          width: ac(580px, 390px, 992);
          @media (max-width: 992px) {
            width: ac(565px, 327px, 620, 992);
            height: ac(565px, 327px, 620, 992);
          }
          @media (max-width: 374px) {
            width: ac(327px, 280px, 320, 374);
            height: ac(327px, 280px, 320, 374);
          }
        }
        &__decoration {
          max-width: ac(540px, 303px);
          @media (max-width: 992px) {
            max-width: ac(540px, 303px, 620, 992);
          }
          @media (max-width: 374px) {
            width: ac(303px, 260px, 320, 374);
            height: ac(303px, 260px, 320, 374);
          }
        }
      }
    }
    .btn-border {
      background-color: var(--cl-navy-light);
      color: var(--cl-navy);
      border-color: var(--cl-blue);
      text-transform: uppercase;
      &:hover {
        color: var(--cl-blue-light);
      }
    }
  }
}
