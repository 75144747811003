.accreditations {
  padding-top: ac(80px, 64px);
  padding-bottom: ac(80px, 64px);
  background-color: var(--cl-blue-light-2);
  .cont {
    padding-left: ac(33px, 20px);
  }
  &-nav {
    margin-bottom: ac(64px, 38px);
  }
  &-name {

  }
  &-title {
    color: var(--cl-navy);
    margin-bottom: ac(40px, 24px);
  }
  &-tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #B9DFEF;
    flex-wrap: wrap;
    row-gap: 16px;
    @media (max-width: 768px) {
      column-gap: 18px;
    }
    @media (max-width: 416px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-tab {
    padding: ac(8px, 6px) ac(32px, 20px);
    background-color: transparent;
    color: var(--cl-navy);
    font-weight: bold;
    font-size: ac(16px, 14px, 320, 576);
    text-transform: uppercase;
    line-height: 1.32;
    letter-spacing: 1.28px;
    height: 44px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s all;
    @media (max-width: 768px) {
      padding: ac(8px, 6px) 0;
    }
    &:hover {
      color: var(--cl-navy-500);
    }
    &.active {
      background-color: var(--cl-blue);
      color: #FFF;
      @media (max-width: 768px) {
        padding-left: 12px;
        padding-right: 12px;
      }
      @media (max-width: 374px) {
        padding-left: 7px;
        padding-right: 7px;
        font-size: 13px;
      }
    }
  }

  /* content wrapper */
  &-wrap {

  }

  /* each content */
  &-content {
    display: flex;
    gap: ac(40px, 24px);
    display: none;
    &.active {
      display: flex;
    }
    @media (max-width: 970px) {
      flex-direction: column;
    }
    /* left text block */
    &__body {
      width: 70%;
      /* max-width: ac(522px, 410px, 980, 1440); */
      @media (max-width: 970px) {
        width: 100%;
      }
      /* logo + title */
      &-nav {
        display: flex;
        align-items: center;
        margin-bottom: ac(24px, 20px);
        @media (max-width: 576px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 18px;
        }
      }
      &-logo {
        max-width: ac(207px, 120px);
        width: 100%;
        height: auto;
        padding: ac(16px, 12px) ac(24px, 20px);
        background-color: #FFF;
        margin-right: ac(24px, 20px);
        border: 1px solid #80CAE0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-title {
        color: var(--cl-black);
      }

      &-subtitle {
        color: var(--cl-navy);
        margin-bottom: 16px;
        font-weight: 500;
        font-family: var(--font-second);
        line-height: 1.4;
      }
      &-descr {
        color: var(--cl-dark-gray);
        line-height: 1.56;
        p {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }


    /* right scroll text */
    &__text {
      background-color: #FFF;
      padding: ac(40px, 20px);
      padding-bottom: ac(32px, 18px);
      border: 1px solid #80CAE0;
      height: fit-content;
      &-wrap {
        overflow: auto;
        max-height: 435px;
        padding-right: ac(8px, 6px);

        scrollbar-width: inherit; 
        scrollbar-color: #2B94B8 #B9DFEF; 

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 8px;
        }
      
        &::-webkit-scrollbar-track {
          background: var(--cl-blue);
          border-radius: 1px!important;
          margin-block: 5px;
        }
      
        &::-webkit-scrollbar-thumb {
          background: var(--cl-blue-light);
          border-radius: 0!important;
          /* box-shadow: 0 0 1px rgba(255, 255, 255, .5); */
        }
        
      }
      &-title {
        margin-bottom: ac(32px, 20px);
        color: var(--cl-black);
      }
      &-descr {
        ul {
          li {
            padding-left: ac(22px, 16px);
            color: #35353F;
            position: relative;
            line-height: 1.56;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: ac(10px, 9px, 640);
              --size: ac(8px, 6px);
              width: var(--size);
              height: var(--size);
              background-color: var(--cl-blue);
            }
            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
      }
    }

  }
}