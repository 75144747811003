.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-blue) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;

  color: var(--cl-black);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-blue-light);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-blue-light);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding-left: ac(26px, 24px);
  padding-right: ac(16px, 24px);
  @media (max-width: 574px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
/* .cont {
  margin: 0 auto;
  max-width: 1276px;
  width: perc(1276);

  @mixin max-lg {
    width: 89.33%;
  }
} */

section {
  position: relative;
  max-width: 100vw;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__wrap {
    width: 100%;
  }
  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
    text-align: center;
  }

  h1 {

  }
  p {
    font-size: ac(20px, 18px);
    font-weight: 500;
  }

  a {
    /* display: inline-block; */
    /* margin-top: 20px; */
  }
  &__link {
    margin-top: ac(20px, 15px);
  }
}

.section-name {
  font-size: ac(14px, 13px);
  font-weight: 700;
  color: var(--cl-blue);
  margin-bottom: 12px;
  line-height: 1.32;
  letter-spacing: 2.22px;
  text-transform: uppercase;
}

.max-line-length-number {
  padding: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: var(--max-line-num);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.swiper-scrollbar {
  border-radius: 0;
  &-drag {
    border-radius: 0;
  }
}

.top-bar {
  background-color: var(--cl-blue);
  height: var(--top-bar-height);
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(43, 148, 184, 0.5);
  z-index: 78;
  position: absolute;
  top: 0;
  width: 100%;
  transition: .3s all;
  &.fixed {
    position: fixed;
  }

  @media (min-width: 768px) {
    backdrop-filter: blur(12px);
  }
  &__wrap {
    display: flex;
    justify-content: flex-end;
    gap: ac(14px, 12px);
    padding-right: ac(12px, 0);
    @media (max-width: 576px) {
      justify-content: flex-start;
    }
  }
  &__link {
    font-size: ac(14px, 13px);
    line-height: 1.5;
    color: #FFF;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: ac(12px, 10px);
    letter-spacing: .28px;
    i {
      font-size: ac(16px, 12px);
      color: #FFF;
    }
    span {
      text-decoration: underline;
    }
    &:hover {
      span {
        text-decoration: none;
      }
    }
  }
}

[data-parallax] {
  opacity: 0;
  transform: scale(0.8);
  /* scale: 0.8; */
  transition: transform 0.1s ease-in, opacity 0.2s ease-out, scale .3s all;
}

[data-parallax].visible {
  opacity: 1;
  transform: scale(1);
}

.rotate-180 {
  transform: rotate(180deg);
}