/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  /* top: var(--top-bar-height); */
  top: var(--top-bar-height);
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid rgba(43, 149, 184, 1);
  height: var(--header-height);

  &.absolute {
    position: absolute;
  }
  .cont {
    padding-right: ac(23px, 24px);
    padding-left: ac(35px, 24px);
    z-index: 3;
  }

  &.header-white {
    &::after {
      background: rgb(255, 255, 255);
    }
  }
  &.fixed {
    position: fixed;
  }
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    backdrop-filter: blur(12px);
    background: rgba(5, 61, 128, 0.5);
    opacity: 0.98;
  }

  /* hz */
  /* &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  } */

  &.scrolled {
    &:not(.open-menu) {
      height: calc(var(--header-height) / 1.5);
      .logo {
        /* max-width: 180px; */
        transform: translateX(-15px);
        scale: 0.87;
        @mixin max-xs {
          max-width: 165px;
        }
      }
    }
  }


  .logo {
    height: auto;
    width: 100%;
    max-width: ac(236px, 180px);
    transition: transform 0.25s ease, max-width 0.25s ease, scale .3s ease;
    z-index: 100;
    transform: translateX(0);
    scale: 1;
    &:hover {
      transform: scale(1.04);
    }
    img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 640px) {
      max-width: 185px;
    }
  }

  /* &.scrolled {
    .logo {
      width: 90%;
    }
  } */

  /* base descktop navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* padding: ac(13px, 10px) ac(50px, 42px);
    @media (max-width: 768px) {
      padding: 6px 12px 6px 24px;
    } */
    &.active {
      margin-right: 11px;
      @media (pointer: coarse) {
        margin-right: 0;
      }
    }

  }

  .burger {
    display: none;
  }

  /* list, menu */
  .navbar-nav {
    display: flex;
    flex-grow: 1;
    position: relative;
    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &-bg {
      display: none;
      position: absolute;
      top: -420px;
      right: -81%;
      width: 3000px;
      height: auto;
      opacity: .5;
    }
    /* min-width: 1200px */
    @mixin mobile-menu-end-point {
      width: auto;
      flex: 1;
      /* padding-left: ac(60px, 20px); */
      align-items: center;
      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(32px, 20px);
        }

        &:after {
          display: none;
        }
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  /* .navbar-nav.active .menu {
    &-bg {
      @media (max-width: 1200px) {
        display: flex;
      }
      @media (max-width: 640px) {
        opacity: 1;
        top: -440px;
        right: ac(-640px, -1190px, 375, 640); 
      }
    }
  } */

  .menu-item {
    position: relative;
    &__icon {
      display: none;
    }
    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '\e999';
          font-family: 'icomoon';
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  /* mobile menu addt content */
  .menu-additional-content {
    margin-left: ac(48px, 24px);
    .btn {
      text-transform: uppercase;
    }
    /* display: none; */
    @mixin mobile-menu-start-point {
      /*transform: translateY(-8px);*/
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: auto;
      margin-left: 0;
      .btn {
        width: 100%;
      }
    }
  }

  /* white header btn */
  &.header-white {
    .menu-additional-content {
      .btn {
        background-color: var(--cl-navy-light);
        border-color: var(--cl-blue);
        color: var(--cl-navy);
        &:hover {
          background-color: var(--cl-blue-light-2);
        }
      }
    }
  }

  /* &:not(.header-mobile) {
    .menu-item {
      @mixin mobile-menu-end-point {
        padding: 5px 0;
      }

      &:hover {
        @mixin mobile-menu-end-point {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  } */

  /* nav-list */
  .menu-link {
    position: relative;
    font-size: ac(17px, 16px);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: .2px;
    color: var(--cl-white);
    transition: all 0.3s ease;
    letter-spacing: 0.33px;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      bottom: -6.5px;
      right: 0;
      width: 0;
      height: 1px;
      background: var(--cl-blue-light);
      transition: all 0.3s ease;
    }
    &.active {
      &::before {
        width: 100%;
        left: 0;
      }
    }

    &:hover {
      color: var(--cl-white-80);

      &::before {
        width: 100%;
        left: 0;
        background: var(--cl-blue-light-80);
      }
    }
  }

  /* header white links */
  &.header-white {
    .menu-link {
      color: var(--cl-navy);
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--cl-grey);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

/* min-1200? */
@mixin mobile-menu-start-point {
  /* mobile menu */
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
      margin-right: -2px;
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      /* top: var(--header-height); */
      top: calc(var(--header-height) + var(--top-bar-height) - 1.5px);
      width: 46%;
      /* height: 100%; */
      max-height: calc(var(--vh) * 100);
      height: calc(var(--vh) * 100);
      min-width: 320px;
      padding-top: ac(32px, 20px);
      z-index: -1;
      background: var(--cl-primary);
      padding-right: 5px;
      padding-bottom: 120px;
      transition: opacity 0.25s ease-out, clip-path 0.5s ease,
        background-color 0.4s ease 0.1s, border-radius 0.3s ease;
      clip-path: circle(25px at calc(100% - 45px) 45px);
      opacity: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      pointer-events: none;
      /* @media (min-width: 650px) {
        width: 51% !important;
        .menu-list {
          padding-left: ac(60px, 0px, 650, 1024);
        }
      } */
      /* @mixin media 970 {
        width: 67%;
      } */

      &__image {
        position: absolute;
        inset: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      /* mobile menu active */
      &.active {
        pointer-events: auto;
        clip-path: circle(75%);
        opacity: 1;
        background-color: rgba(5, 61, 128, 0.5);
        backdrop-filter: blur(12px);
      }

      /* animation */
      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }
      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }
      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }
      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      /* list, additional content */
      .menu {
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        padding-right: 15px;
        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;
        overflow-x: hidden;
        overflow-y: auto;
        .simplebar-wrapper {
          flex: 1;
        }
        .menu-additional-content {
          padding-bottom: 50px;
        }
        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          height: 100%;

          .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 24px;
            position: relative;

            @media screen and (max-height: 730px) {
              padding-top: 32px;
            }

            &:after {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 0;
              width: 173px;
              height: 1px;
              background-color: var(--cl-white);
              content: '';
            }
          }
        }

        .simplebar-track.simplebar-vertical {
          width: ac(6px, 4px);
          background: var(--cl-grey);
          border-radius: 4px;
          /*transform: translateX(ac(6px, 4px));*/
          .simplebar-scrollbar {
            background: var(--cl-navy);
            border-radius: 4px;
            &:before {
              content: none;
            }
          }
        }
      }

      /* mobile list links style */
      .menu-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* padding: ac(20px, 16px) ac(28px, 20px); */
        font-size: ac(24px, 22px);
        font-weight: 400;
        margin-left: 0;
        line-height: 1.4;
        width: auto;
        color: var(--cl-white);
        &:before {
          height: 1px;
        }
        &:hover {
          color: var(--cl-white-80) !important;
        }
        .active {

        }
      }

      /* mobile list items style */
      .menu-item {
        width: auto;
        margin-bottom: ac(22px, 20px);
        &:last-of-type {
          margin-bottom: 63px;

          @media screen and (max-height: 700px) {
            margin-bottom: 32px;
          }
        }
      }


      /* padding-bottom: 100px; */
      /* full mobile menu 640 */
      @mixin max-sm {
        width: 100%;
        height: calc(var(--vh) * 100);
        padding-bottom: 100px;
        border-bottom-left-radius: 0;
        .menu {
          padding-left: 20px;
        }

        .menu-list {
          padding-top: 32px;
          &::after {
            display: none;
          }
        }

        .menu-item {
          justify-content: center;
        }

        .menu-additional-content {
          margin-top: auto;
          justify-content: flex-end;  
          padding-bottom: 50px;
          &__buttons {
          }
        }
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }

  .header {
    &.scrolled {
      .navbar-nav {
        /* top: calc(var(--header-height) + ac(4px, 6px, 640, 1200)); */
                /* top: calc(var(--header-height) + ac(4px, 6px, 640, 1200)); */
          top: calc(calc(var(--header-height) / 1.5) + var(--top-bar-height) - 1.5px);
      }
    }
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */

/* burger */
.burger,
.menu-toggle {
  background: var(--cl-blue-light);
  position: relative;
  z-index: 100;
  overflow: hidden;
  transition: 0.3s;
  border: 1px solid var(--cl-blue-light);
  width: 48px;
  height: 48px;
  /* base burger */
  .burger-icon {
    cursor: pointer;
    transition: transform 400ms, background-color 0.3s ease;
    user-select: none;
    /* width: 56px !important;
    height: 56px !important; */
    position: relative;
    z-index: 2;
  }

  .line {
    fill: none;
    transition: stroke 0.3s ease, stroke-dasharray 400ms,
      stroke-dashoffset 400ms;
    stroke: var(--cl-black);
    stroke-width: 3;
    stroke-linecap: round;
  }

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  /* active burger */
  &.active {
    background-color: transparent;
    &:before {
      opacity: 1;
    }
    .line {
      stroke: var(--cl-blue-light);
    }
    .burger-icon {
      transform: rotate(45deg);
    }

    .top {
      stroke-dashoffset: -88px;
    }

    .bottom {
      stroke-dashoffset: -88px;
    }
  }

  /* base burger + hover */
  &:hover {
    .line {
      stroke: var(--cl-dark-gray);
    }
  }

  /* active burger + hover */
  &.active {
    &:hover {
      .line {
        stroke: var(--cl-blue-light-80);
      }
    }
  }
}

/* open menu bg */
.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.35);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */
