.careers-section {
  overflow: hidden;
  background-color: var(--cl-navy);
  @media (max-width: 992px) {
    padding-bottom: 32px;
  }
  &-cont {
    padding-right: ac(24px, 20px);
    @media (max-width: 992px) {
      padding-right: 0;
    }
  }
  &-wrap {
    display: flex;
    align-items: stretch;
    gap: ac(71px, 32px, 992);
    @media (min-width: 1460px) {
      justify-content: center;
    }
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
    }
  }
  &-image {
    width: 676px;
    height: 100%;
    @media (max-width: 992px) {
      width: 100%;
    }
    @media (max-width: 576px) {
      height: 300px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: -70px 0px;
      @media (max-width: 992px) {
        object-position: 0;
      }
    }
    &__wrap {
      min-height: 100%;
      position: relative;
      overflow: hidden;
      min-width: 460px;
      @media (max-width: 1200px) {
      }
      @media (max-width: 992px) {
        width: 100vw;
        max-width: 768px;
        min-width: auto;
      }
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(5, 60, 128, 0.3);
      }
    }
    &__decoration {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      @media (max-width: 992px) {
        bottom: ac(-52px, 0, 375, 992);
      }
      img {
        width: 100%;
      }
    }
  }

  &-text {
    padding-top: ac(80px, 40px);
    padding-bottom: ac(80px, 32px);
    color: var(--cl-white);
    max-width: 585px;
    /* transform: translateX(ac(-60px, -145px, 992)); */
    @media (max-width: 1200px) {
      /* width: ac(655px, 485px, 992); */
      /* max-width: 768px; */
    }
    @media (max-width: 992px) {
      padding: 0;
      width: 100%;
      transform: translateX(0);
      padding-left: ac(24px, 20px);
      padding-right: ac(24px, 20px);
    }
  }
  &-name {

  }
  &-title {
    margin-bottom: 24px;
    max-width: 385px;
    color: var(--cl-navy-light);
  }
  &-subtitle {
    margin-bottom: ac(16px, 20px);
    font-size: ac(24px, 20px);
    line-height: 1.48;
    @media (max-width: 576px) {
      line-height: 1.4;
    }
  }
  &-descr {
    margin-bottom: ac(40px, 32px);
    color: var(--cl-light-gray);
    p {
      &:not(:last-child) {
        margin-bottom: ac(20px, 10px);
      }
    }
  }
  &-buttons {
    display: flex;
    gap: ac(32px, 20px);
    @media (max-width: 620px) {
      flex-direction: column;
      .btn-link .btn__icon {
        width: 7px;
      }
    }
    .btn-base {

    }
    .btn-link {

    }
  }
  &-btn {
    @media (max-width: 620px) {
      width: 100%;
    }
  }


  /* about page */
  &-about {
    .careers-section {
      &-title {
        max-width: ac(400px, 380px);
      }
      &-subtitle {
        margin-bottom: ac(40px, 24px);
      }
      &-buttons {
        .btn-link {
          .btn__icon {
            margin-left: ac(9px, 4px);
            i {
              font-size: ac(19px, 14px);
            }
          }
        }
      }
      &-image {
        @media (max-width: 576px) {
          height: auto;
        }
        &__wrap {
          min-width: 560px;
          @media (max-width: 992px) {
            min-width: auto;
          }
        }
        img {
          object-position: 0 0;
        }
      }
    }
  }

  /* services page */
  &-services {
    .careers-section {
      &-wrap {
        gap: ac(112px, 32px);
      }
      &-image {
        img {
          object-position: ac(0, -77px, 992, 1200) 0;
          @media (max-width: 992px) {
            object-position: 0 0;
          }
        }
        &__wrap {
          &::before {
            display: none;
          }
        }
      }
      &-text {
        max-width: 544px;
        @media (max-width: 992px) {
          max-width: 768px;
        }
      }
      &-descr {
        font-size: ac(20px, 18px);
        line-height: 1.4;
        font-weight: 500;
        font-family: var(--font-second);
        color: #FFF;
      }
    }
  }

  /* health safety page */
  &-health {
    background: var(--cl-blue-light-2);
    .careers-section {
      &-wrap {
        align-items: center;
        @media (max-width: 992px) {
          align-items: flex-start;
        }
      }
      &-image {
        @media (max-width: 1200px) {
          width: ac(676px, 616px, 992, 1200);
        }
        @media (max-width: 992px) {
          width: 100%;
        }
        img {
          object-position: 0 0;
          @media (max-width: 1200px) {
            object-position: ac(0px, -75px, 992) 0;
          }
          @media (max-width: 992px) {
            object-position: 0 0;
          }
        }
      }
      &-text {
        padding-top: ac(80px, 40px);
      }
      &-title {
        color: var(--cl-navy);
      }
      &-subtitle {
        color: var(--cl-dark-gray);
        margin-bottom: ac(40px, 18px);
      }
    }
    .btn-base {

    }
    .btn-link {
      .btn__icon {
        margin-left: ac(9px, 4px);
        i {
          font-size: ac(19px, 14px);
        }
      }
    }
    .btn-link {
      color: var(--cl-navy);
      .btn__icon i {
        color: var(--cl-blue);
      }
    }
  }
}
