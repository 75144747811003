.careers {
  padding-top: ac(80px, 64px);
  padding-bottom: ac(120px, 80px);
  overflow: hidden;
  .cont {
    padding-left: ac(33px, 20px);
    padding-right: ac(22px, 20px);
  }
  &-row {
    display: flex;
    justify-content: space-between;
    gap: ac(24px, 18px);
    @media (max-width: 680px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 0;
    }
  }
  &-block {
    margin-bottom: ac(40px, 32px);
    @media (max-width: 980px) {
      width: 100%;
    }
    &__title {
      margin-bottom: ac(28px, 20px);
      color: var(--cl-navy);
    }
    &__content {
      background-color: var(--cl-blue-light-2);
      border: 1px solid var(--cl-blue-light);
      padding: ac(40px, 18px, 920);
      padding-top: ac(34px, 16px, 920);
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: ac(24px, 18px, 980);
      row-gap: ac(18px, 16px, 980);
      max-width: ac(592px, 480px);
      @media (max-width: 980px) {
        max-width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }
      .meta-item {
        width: 100%;
        max-width: ac(241px, 187px, 980);
        .meta-input__date-container {
          width: 100%;
        }
        @media (max-width: 980px) {
          max-width: 768px;
        }
      }
    }

    &-full {
      .careers {
        &-block__content {
          max-width: none;
          justify-content: space-between;
          flex-direction: row;
          row-gap: ac(46px, 32px);
          &-item {
            max-width: ac(550px, 426px, 980);
            @media (max-width: 980px) {
              width: 100%;
              max-width: none;
            }
            .meta-checkbox-list {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              row-gap: ac(20px, 14px);
              column-gap: ac(20px, 18px);
            }
          }
          &-subtitle {
            width: 100%;
            padding-bottom: ac(14px, 12px);
            margin-bottom: ac(26px, 18px);
            color: var(--cl-navy);
            border-bottom: 1px solid #80CAE0;
          }
        }
      }
    }

    &-cv {
      .careers-block__content {
        padding-right: ac(54px, 18px);
        @media (max-width: 820px) {
          flex-direction: column;
        }
      }
      &__upload {
        width: 39%;
        /* max-width: ac(440px, 340px, 820); */
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%232B94B8' stroke-width='3' stroke-dasharray='7%2c 13' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
        @media (max-width: 820px) {
          max-width: 768px;
          width: 100%;
        }
        .meta-upload-file__input {
          border-color: transparent;
        }
        &:hover {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23053D80' stroke-width='3' stroke-dasharray='7%2c 13' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
        }
        .btn {
          .btn__text {
            text-transform: uppercase;
          }
        }
      }
      &__message {
        width: 58%;
        @media (max-width: 820px) {
          width: 100%;
        }
        textarea {
          /* width: ac(648px, 350px, 820); */
          width: 100%;
          height: ac(200px, 160px);
          @media (max-width: 820px) {
            width: 100%;  
          }
        }
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ac(40px, 32px);
    gap: ac(24px, 14px);
    .meta-checkbox__text {
      font-size: ac(16px, 12px, 420);
      color: var(--cl-mid-gray);
      a {
        font-weight: 500;
      }
    }
  }
  &-submit {
    max-width: ac(287px, 240px);
    width: 100%;
  }
}