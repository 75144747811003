/* internal studies first section (slider) */
.studies {  
  background-color: var(--cl-blue-light-2);
  padding-top: ac(200px, 126px);
  padding-bottom: ac(40px, 32px);
  @media (max-width: 890px) {
    padding-top: ac(180px, 140px, 375, 890);
  }
  .cont {
    padding-left: ac(33px, 20px);
  }
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ac(32px, 20px);
    gap: ac(20px, 12px);
    @media (max-width: 890px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: ac(48px, 32px, 320, 890);
    }
  }
  &-text {
    max-width: ac(512px, 480px);
    margin-top: ac(-28px, 0, 1200);
    @media (max-width: 890px) {
      max-width: 576px;
      margin-top: 0;
    }
  }
  &-tags {
    margin-bottom: ac(20px, 16px);
    display: flex;
    gap: 8px;
  }
  &-tag {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2.24px;
    font-weight: bold;
    padding: ac(7px, 6px) ac(12px, 10px);
    line-height: 1.32;
    color: var(--cl-blue);
    border: 1px solid var(--cl-light-gray);
  }
  &-title {
    color: var(--cl-navy);
    margin-bottom: ac(24px, 20px);
    max-width: ac(480px, 450px);
    @media (max-width: 890px) {
      max-width: none;
    }
  }
  &-descr {
    color: var(--cl-dark-gray);
  }

  /* first slider */
  &-slider {
    max-width: ac(708px, 480px, 900);
    overflow: hidden;
    margin-left: 0;
    margin-right: ac(-100px, -22px, 1285);
    margin-top: ac(-65px, -10px, 900);
    width: 100%;
    @media (max-width: 890px) {
      max-width: 676px;
      margin: 0;
    }
    &__item {
      max-width: ac(708px, 480px, 900);
      img {
        /* aspect-ratio: 1.5 / 1; */
        aspect-ratio: 3 / 2;
        width: 100%;
        height: auto;
      }
      @media (max-width: 890px) {
        max-width: 676px;
      }
    }
  }

  /* second slider */
  &-track {
    position: relative;
    &::before {
      content: '';
      left: -55%;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, #ecf5fa 40%, #ecf5fa08 55%);
      z-index: 3; 
      pointer-events: none;
    }
    &::after {
      content: '';
      right: -55%;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to left, #ecf5fa 40%, #ecf5fa00 55%);
      z-index: 3; 
      pointer-events: none;
    }
    &__slider {
      max-width: ac(231px, 180px, 900);
      overflow: visible;
      margin: 0;
      @media (max-width: 890px) {
        max-width: ac(200px, 140px, 320, 890);
      }
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: ac(40px, 24px);
      padding-right: ac(7px, 0px);
      width: 100%;
      gap: ac(32px, 24px);
      @media (max-width: 820px) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        gap: ac(24px, 16px, 375, 820);
      }
    }
    .swiper-button {
      border-color: var(--cl-blue);
      &.swiper-button-track__active {
        background-color: var(--cl-blue);
        &::before {
          color: #FFF;
        }
      }

    }
    .swiper-scrollbar {
      position: relative;
      width: 100%;
      inset: auto;
      background-color: #B9DFEF;
      &-drag {
        background-color: var(--cl-blue);
      }
    }
    &__item {
      max-width: ac(231px, 180px, 900);
      transition: .3s all;
      position: relative;
      cursor: pointer;
      img {
        aspect-ratio: 1 / 0.67;
      }
      @media (max-width: 890px) {
        max-width: ac(200px, 140px, 320, 890);
      }
      &-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 0;
        opacity: 0;
        transition: .4s all;
      }
      &::after {
        content: '';
        border: 4px solid #1AC4FF;
        position: absolute;
        inset: 0;
        background: radial-gradient(50% 50% at 50% 50%, hsla(195, 59%, 46%, 0) 0%, rgb(43, 148, 184) 100%);
        opacity: 0;
        transition: .3s all;
      }
      &::before {
        content: "\e90c";
        font-size: ac(56px, 42px);
        color: #FFF;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: .3s all;
        z-index: 2;
        /* iconmoon */
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
      
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &.swiper-slide-thumb-active {
        &::after {
          opacity: 0.6;
        }
        &::before, .studies-track__item-overlay {
          opacity: 1;
        }
      }
    }
  }
}