.clients {
  padding-top: ac(62px, 48px);
  padding-bottom: ac(48px, 40px);
  overflow: hidden;
  position: relative;
  z-index: 5;
  width: 100vw;
  background-color: var(--cl-blue-light-2);
  &-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: ac(50px, 36px);
  }
  &-name {

  }
  &-title {
    color: var(--cl-navy);
  }
  &-wrap {

  }
  &-slider {
    overflow: visible;
    /* slide width * slidesPerView */
    max-width: ac(184px, 160px);
    &__bottom {
      display: none;
      width: 100%;
      gap: ac(32px, 24px);
      @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 28px;
      }
      @media (max-width: 820px) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        gap: ac(24px, 16px, 375, 820);
      }
    }
  }

.swiper-scrollbar {
  position: relative;
  width: 100%;
  inset: auto;
  background-color: #B9DFEF;
  &-drag {
    background-color: var(--cl-blue);
  }
}

  &__logo {
    display: grid;
    place-items: center;
    padding: ac(16px, 8px) ac(32px, 28px);
    background: transparent;
    width: 100%;
    max-width: ac(184px, 160px);
    height: ac(104px, 90px);
    overflow: hidden;
    border-left: 0.5px solid var(--cl-blue-light);
    /* border-right: 0.25px solid var(--cl-blue-light); */
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }


  @media (min-width: 1536px) {
    .cont {
      max-width: 100%;
      padding: 0;
    }
  }

  &-only-logos {
    padding-top: ac(48px, 40px);
  }
}