.footer {
  background-color: var(--cl-navy);
  padding-top: ac(80px, 40px);
  padding-bottom: ac(80px, 32px);
  overflow: hidden;
  &-wrap {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1180px) {
      row-gap: ac(42px, 32px);
    }
    @media (max-width: 992px) {
      justify-content: flex-start;
      gap: ac(92px, 32px, 375, 992);
    }
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
  &-block {
    &__title {
      font-size: ac(24px, 22px);
      line-height: 1.45;
      margin-bottom: ac(24px, 16px);
      color: var(--cl-navy-light);
    }
  }

  &-info {
    margin-right: ac(142px, 50px, 1024);
    @media (max-width: 600px) {
      margin-right: 0;
    }
  }

  &-logo {
    max-width: ac(392px, 327px);
    width: 100%;
    margin-bottom: ac(29px, 12px);
    transition: .3s all;
    &:hover {
      opacity: .95;
      transform: scale(1.01);
    }
    img {
      width: 100%;
    }
  }
  &-subtitle {
    font-size: ac(26px, 24px);
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--cl-navy-light);
  }
  &-btn {
    @media (max-width: 420px) {
      width: 100%!important;
    }
  }

  &-site-map { 
    margin-right: ac(93px, 65px, 992);
    @media (max-width: 600px) {
      margin-right: 0;
    }
  }
  &-list {
    @media (max-width: 600px) {
      columns: 2;
      max-width: 250px;
    }
    &__item {
      margin-bottom: ac(12px, 8px);
      line-height: 1!important;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        font-size: ac(18px, 16px);
        line-height: 1.48;
        letter-spacing: .02px;
        color: var(--cl-blue-light-2);
        transition: .3s;
        font-weight: 400;
        /*text-decoration: underline;
        text-decoration-color: transparent;*/
        position: relative;

        &:after {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 1px;
          width: 0;
          opacity: 0;
          content: "";
          background: var(--cl-blue-light-2);
          transition: opacity .3s ease, width .3s ease;
        }

        &:hover {
          /*text-decoration-color: var(--cl-blue-light-2);*/

          &:after {
            opacity: 1;
            right: auto;
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }

  &-contact {
    max-width: ac(365px, 326px, 992);
    margin-right: ac(49px, 20px, 992);
    @media (max-width: 600px) {
      margin-right: 0;
    }
    &__row {
      display: flex;
      align-items: center;
      gap: 14px;
      @media (max-width: 374px) {
        flex-direction: column;
        align-items: flex-start;
        a:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
    &__link {
      font-size: ac(18px, 16px);
      font-weight: 600;
      letter-spacing: 0.36px;
      color: #ECF5FA;
      transition: .3s all;
      display: flex;
      line-height: 32px;
      align-items: center;
      gap: 15px;
      margin-bottom: ac(20px, 12px);
      width: fit-content;
      @media (max-width: 768px) {
        letter-spacing: 0.32px;
        line-height: 30px;
      }
      i {
        color: #F1F3FE;
        font-size: ac(20px, 16px);
        &.icon-mail {
          margin-top: 4px;
        }
      }
      span {
        text-decoration: underline;
      }
      &:hover {
        color: rgba(236, 245, 250, 0.818);
      }
    }
    &__location { 
      margin-bottom: 0;
      i {

      }
      span {
        /* text-decoration: none; */
      }
    }
  }

  &-social {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 1180px) {
      flex-direction: row;
      justify-content: flex-start;
      .footer-block__title {
        margin-bottom: 0;
        margin-right: 24px;
      }
    }
    @media (max-width: 992px) {
      flex-direction: column;
      justify-content: flex-start;
      .footer-block__title {
        margin-bottom: 24px;
        margin-right: 0;
      }
    }
    @media (max-width: 600px) {
      flex-direction: row;
      justify-content: flex-start;
      .footer-block__title {
        margin-bottom: 0;
        margin-right: 24px;
      }
    }
    &__item {
      border: 1px solid var(--cl-blue-light-2);
      background-color: var(--cl-blue);
      display: flex;
      align-items: center;
      justify-content: center;
      --size: 40px;
      height: var(--size);
      width: var(--size);
      margin-bottom: 8px;
      transition: .25s all;
      @media (max-width: 1180px) {
        margin-right: 8px;
        margin-bottom: 0;
      }
      @media (max-width: 992px) {
        margin-bottom: 8px;
        margin-right: 0;
      }
      @media (max-width: 600px) {
        margin-right: 8px;
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
      i {
        font-size: ac(20px, 16px);
        color: var(--cl-blue-light-2);
        transition: .25s all;
      }
      &:hover {
        background-color: var(--cl-blue-light);
        i {
          color: var(--cl-blue-900);
        }
      }
    }
  }

  &-bottom {
    background-color: #F1F3FE;
    padding: ac(12px, 15px) 0;
    overflow: hidden;
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap-reverse;
      gap: 14px;
      padding-right: ac(20px, 0);
    }
    &__copy {
      font-size: ac(14px, 13px);
      line-height: 1.5;
      color: var(--cl-dark-gray);
      font-family: var(--font-second);
    }
    &__links {
      gap: ac(32px, 18px);
      display: flex;
    }
    &__link {
      font-size: ac(14px, 13px);
      line-height: 1.5;
      font-family: var(--font-second);
      color: var(--cl-dark-gray);
      transition: .15s all;
      font-weight: 400;
      letter-spacing: .035px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}