.services {
  padding-bottom: ac(80px, 48px);
  margin-top: ac(-120px, -64px);
  overflow: hidden;
  position: relative;
  &-decoration {
    position: absolute;
    z-index: -1;
    inset: 0;
    width: 100%;
    height: 100%;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover; 
    max-width: 1920px;
    @media (max-width: 992px) {
      top: 30%;
    }
  }
  .cont {
    padding-left: ac(33px, 20px);
    z-index: 2;
    position: relative;
  }
  &-wrap {

  }

  .swiper {
    overflow: visible;
    @media (max-width: 992px) {
      max-width: 520px;
    }
    @media (max-width: 576px) {
      max-width: ac(520px, 310px, 320, 576);
    }
    &-wrapper {
      @media (min-width: 993px) {
        grid-column-gap: ac(24px, 20px);
        grid-row-gap: ac(20px, 18px);
        grid-template-columns: repeat(2, minmax(0, 1fr));
        display: grid;
      }
    }
  }
  .swiper-buttons {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      margin: ac(32px, 28px, 375, 992) auto -20px auto;
    }
  }

  &-card {
    background-color: #FFF;
    border: 1px solid rgba(43, 149, 184, 0.5);
    padding: ac(32px, 22px);
    padding-bottom: ac(28px, 24px);
    width: 100%;
    height: auto;
    max-width: 600px;
    &__nav {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__icon {
      max-width: 152px;
      height: ac(104px, 76px);
      padding: ac(16px, 10px) ac(40px, 28px);
      border: 2px solid var(--cl-blue);
      border-radius: 100px;
      margin-right: ac(20px, 16px);
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 8px;
      }
      img {
        max-width: ac(72px, 52px);
        width: 100%;
        height: auto;
      }
    }
    &__title {
      font-size: ac(44px, 32px);
      font-weight: bold;
      color: var(--cl-navy);
      line-height: 1.2;
      
    }
    &__descr {
      font-size: ac(24px, 20px);
      line-height: 1.48;
      color: var(--cl-navy);
      --max-line-num: 4;
      margin-bottom: 16px;
    }
    &__btn {
      color: var(--cl-navy);
      .btn__icon {
        i {
          color: var(--cl-blue);
        }
      }
      &-wrap {
        display: flex;
        justify-content: flex-end;
      }
      &:hover {
        color: var(--cl-navy-500);
      }
    }
  }
}