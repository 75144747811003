.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cl-white);
  height: 48px;
  width: fit-content;
  padding: 0 ac(28px, 18px);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.32;
  letter-spacing: .34px;
  transition: .25s all;
  &-border {
    background-color: transparent;
    border: 1px solid var(--cl-blue-light);
    letter-spacing: 1.21px;
    &:hover {
      border-color: var(--cl-blue-light-80);
      color: var(--cl-white-80);
    }
  }
  &-base {
    background-color: #2B94B8;
    text-transform: uppercase;
    letter-spacing: 1.28px;
    &:hover {
      background-color: var(--cl-blue-900);
      color: var(--cl-white-80);
    }
  }
  &-link {
    padding: 0 0;
    text-transform: uppercase;
    letter-spacing: 1.28px;
    .btn__icon {
      margin-left: 4px;
      transition: .2s all;
      display: flex;
      align-items: center;
      i {
        color: #FFF;
        font-size: 15px;
      }
    }
    &:hover {
      color: var(--cl-white-80);
      .btn__icon {
        transform: translateX(3px);
      }
    }
  }
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: 16px;
  position: relative;
  inset: auto;
  .swiper-button {
    position: relative;
    transform: none;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: flex;
    --size: 48px;
    width: var(--size);
    height: var(--size);
    justify-content: center;
    align-items: center;
    color: var(--cl-navy, #16108c);
    border: 1px solid var(--cl-blue-light);
    transition: 0.3s;
    background-color: var(--cl-white);
    &::after {
      display: none;
    }
    &::before {
      content: '\e901';
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'icomoon' !important;
      font-size: ac(18px, 16px);
      margin-top: ac(1px, 0px);
      transition: .3s all;
    }
    &.swiper-button-prev {
      &::before {
        content: '\e900';
      }
    }
    &:hover {
      background-color: var(--cl-white);
      border-color: var(--cl-blue);
      opacity: 1;
      &::before {
        color: var(--cl-blue);
      }
    }
    &.swiper-button-lock {
      display: none;
    }
    &.swiper-button-disabled {
      background-color: var(--cl-white);
      border-color: #B9DFEF;
      opacity: 1;
      &::before {
        color: var(--cl-mid-gray);
      }
    }
  }
}