.contact {
  padding-top: ac(228px, 130px);
  padding-bottom: ac(108px, 64px);
  position: relative;
  &-bg-decoration {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
  &-wrap {
    display: flex;
    justify-content: space-between;
    gap: ac(32px, 24px);
    @media (max-width: 860px) {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }
  }

  /* form + text */
  &-left {
    max-width: ac(620px, 540px);
    @media (max-width: 860px) {
      max-width: 100%;
    }
  }
  &-text {
    margin-bottom: ac(48px, 24px);
  }
  &-subtitle {
    margin-bottom: ac(16px, 14px);
    color: var(--cl-blue);
  }
  &-title {
    margin-bottom: ac(24px, 18px);
    color: var(--cl-navy);
  }
  &-descr {
    color: var(--cl-dark-gray);
  }

  &-form {
    &__row {
      display: flex;
      justify-content: space-between;
      gap: ac(24px, 14px);
      margin-bottom: ac(24px, 14px);
      @media (max-width: 480px) {
        flex-direction: column;
      }
      .meta-input {
        width: 100%;
        input {
          background: #ECF5FA;
          &::placeholder {
            color: #6E6F72;
          }
        }
      }
    }
    .meta-textarea {
      width: 100%;
      margin-bottom: ac(40px, 32px);
      textarea {
        background: #ECF5FA;
        min-height: 96px;
        padding-top: ac(14px, 10px);
        &::placeholder {
          color: #6E6F72;
        }
      }
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: ac(24px, 14px);
      @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .meta-checkbox__text {
        font-size: 14px;
        line-height: 1.56;
        color: var(--cl-dark-gray);
        font-family: var(--font-second);
        max-width: ac(356px, 290px);
        a {
          font-weight: 500;
        }
      }
    }
    &__submit {
      width: 100%;
      max-width: ac(159px, 140px);
    }
  }

  &-info {
    border: 1px solid #80CAE0;
    padding: ac(40px, 24px) ac(48px, 20px);
    background-color: #FFF;
    max-width: ac(500px, 320px);
    margin-right: ac(5px, 0);
    @media (max-width: 860px) {
      max-width: 100%;
    }
    &__title {
      color: var(--cl-navy);
      font-weight: bold;
      font-size: ac(36px, 24px);
      line-height: 1.36;
      margin-bottom: ac(30px, 20px);
    }
    /* email, phone, location */
    &__contacts {
      padding-bottom: ac(40px, 24px);
      margin-bottom: ac(24px, 18px);
      border-bottom: 1px solid var(--cl-blue);
    }
    &__contact {
      display: flex;
      align-items: center;
      margin-bottom: ac(24px, 16px);
      width: fit-content;
      &:last-child {
        margin-bottom: 0;
      }
      i {
        color: var(--cl-blue);
        font-size: ac(24px, 19px);
        margin-right: ac(12px, 8px);
      }
      span {
        color: var(--cl-navy);
        transition: .3s all;
        line-height: 1.4;
        font-weight: 500;
        font-family: var(--font-second);
      }
      &:hover {
        span {
          text-decoration: underline;
          color: var(--cl-blue);
        }
      }
    }

    &__schedule {
      &-title {
        margin-bottom: ac(32px, 18px);
        font-size: ac(26px, 20px);
        color: var(--cl-navy);
        line-height: 1.4;
        font-weight: bold;
      }
      &-wrap {
        @media (max-width: 860px) {
          display: flex;
          flex-wrap: wrap;
          column-gap: 16px;
        }
      }
      &-item {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 14px;
        }
      }
      &-day {
        margin-right: ac(12px, 8px);
        width: 64px;
        font-family: var(--font-second);
        font-size: ac(18px, 16px);
        font-weight: 600;
        color: var(--cl-blue);
        line-height: 1.56;
        border: 1px solid #D0D1D3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-time {
        font-size: ac(18px, 16px);
        font-family: var(--font-second);
        color: var(--cl-black);
        font-weight: 400;
        line-height: 1.56;
      }
    }
  }
}